/**
 * = Sidebars
 */

@include media-breakpoint-up(lg) {
    #doc-index {
        display: block;
    }

    .doc-sidebar {
        display: block;
        height: calc(100vh - 2rem);
        overflow-y: scroll;
        position: -webkit-sticky;
        position: sticky;
        top: 2rem;

        .nav-link[data-toggle="collapse"] > .icon {
            transform: rotateZ(-90deg);
            position: relative;
            right: 0.25rem;
        }

        .nav {
            .nav-item {
                font-size: $font-size-sm;
            }
        }
    }
}
